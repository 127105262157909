<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm" :show="isLoading">
          <div class="m-2">
            <b-row>
              <b-col cols="7">
                <b-form-file
                  ref="inputFileRef"
                  placeholder="Chọn file để import thang điểm đánh giá tổng quan..."
                  drop-placeholder="Drop file here..."
                  accept=".xls, .xlsx"
                  @change="importExcel"
                />
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                  v-show="showTableImport && (isSystemAdmin() || creatable)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    class="ml-1"
                    @click="handleImportSetOfEvaluateLevel"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="CheckSquareIcon" /> Import
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-1"
                    @click="downloadFile"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Tải file mẫu
                    </span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="ml-1"
                    @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất excel
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.saveModal
                    variant="success"
                    class="ml-1"
                    @click="onCreate"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm mới
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-if="props.column.field === 'status'">
                      {{ props.row.status === 1 ? 'Đang hoạt động' : 'Không hoạt động' }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-if="updatable"
                        v-b-modal.saveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onEdit(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDelete(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
          <Save
            :data-source="currentDataSource"
            @succeed="onSucceed"
          />
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BFormFile, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import * as XLSX from 'xlsx'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import Save from '@/views/set-of-evaluate-levels/EvaluateLevels.vue'
import { getUser } from '@/auth/utils'
import { STATUSES } from '@/const/status'

export default {
  name: 'Levels',
  directives: {
    Ripple,
  },
  components: {
    Save,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BFormFile,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        organizationId: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 10,
        code: null,
        name: null,
        status: null,
        sort: null,
      },
      filterExport: null,
      currentDataSource: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      // tableImport: {
      //   columns: [
      //     {
      //       label: 'STT',
      //       field: 'rowNum',
      //       sortable: false,
      //       thClass: 'text-center',
      //       tdClass: 'text-right',
      //     },
      //     {
      //       label: 'Mã thang điểm đánh giá tổng quan',
      //       field: 'code',

      //       sortable: true,
      //       thClass: 'text-center',
      //     },
      //     {
      //       label: 'Tên thang điểm đánh giá tổng quan',
      //       field: 'name',
      //       sortable: true,
      //       thClass: 'text-center',
      //     },
      //     {
      //       label: 'Trạng thái thang điểm đánh giá tổng quan',
      //       field: 'name',
      //       sortable: true,
      //       thClass: 'text-center',
      //     },
      //     {
      //       label: 'Tên mức thang',
      //       field: 'mainName',
      //       sortable: true,
      //       thClass: 'text-center',
      //     },
      //     {
      //       label: 'Giá trị phần trăm nhỏ nhất',
      //       field: 'departmentName',
      //       sortable: true,
      //       thClass: 'text-center',
      //     },
      //   ],
      //   data: [],
      //   totalRows: 0,
      // },
      columns: [
        {
          label: 'Mã thang điểm đánh giá tổng quan',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên thang điểm đánh giá tổng quan',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: [
              { value: 1, text: 'Hoạt động' },
              { value: 0, text: 'Không hoạt động' },
            ],
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      columnsExport: [
        {
          label: 'Mã thang điểm đánh giá tổng quan',
          field: 'code',
        },
        {
          label: 'Tên thang điểm đánh giá tổng quan',
          field: 'name',
        },
        {
          label: 'Trạng thái',
          field: 'status',
        },
        {
          label: 'Tên mức thang',
          field: 'levelName',
        },
        {
          label: 'Giá trị phần trăm nhỏ nhất',
          field: 'minimumPercent',
        },
      ],
      showTableImport: false,
      tenFile: '',
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'setOfEvaluateLevel/dataResoureces',
      totalRows: 'setOfEvaluateLevel/totalRows',
      dataResourecesExport: 'setOfEvaluateLevel/dataResourecesExport',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.SET_OF_EVALUATE_LEVELS)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SET_OF_EVALUATE_LEVELS)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.SET_OF_EVALUATE_LEVELS)
    },
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    isSystemAdmin,
    ...mapMutations({
      setDataSources: 'setOfEvaluateLevel/SET_DATA_SOURCES',
    }),
    ...mapActions({
      getDataSources: 'setOfEvaluateLevel/getDataSources',
      deleteDataSource: 'setOfEvaluateLevel/deleteDataSource',
      importSetOfEvaluateLevel: 'setOfEvaluateLevel/importSetOfEvaluateLevel',
      getDataSourcesExport: 'setOfEvaluateLevel/getDataSourcesExport',
    }),
    async onLoad() {
      this.isLoading = true
      try {
        await this.getDataSourcesFromStore()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.setDataSources([])
      } finally {
        this.isLoading = false
      }
    },
    onCreate() {
      this.currentDataSource = undefined
    },
    onEdit(dataSource) {
      const {
        originalIndex,
        vgt_id,
        ...rest
      } = dataSource
      this.currentDataSource = rest
    },
    onDelete(dataSource) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa <span class="text-danger">${dataSource.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteDataSource(dataSource.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
        const {
          currentPage,
          itemsPerPage,
          ...rest
        } = this.filter
        this.filterExport = rest
        await this.getDataSourcesExport(this.filterExport)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)
        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getDataSourcesFromStore()
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return id >= 0 ? STATUSES.find(status => status.value === id).label : ''
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    async handleImportSetOfEvaluateLevel() {
      this.isLoading = true
      try {
        const res = await this.importSetOfEvaluateLevel(this.dataExcel)
        if (res.code === 1) {
          this.showToast('Import thành công', 'CheckIcon', 'success')
          this.resetInputFile()
          await this.getDataSourcesFromStore()
          this.showTableImport = false
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', 3000)
          this.resetInputFile()
          this.showTableImport = false
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', 3000)
        this.resetInputFile()
        this.showTableImport = false
      } finally {
        this.isLoading = false
      }
    },
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      const checkFileData = this.readFile(files)
      if (checkFileData && !checkFileData.status) {
        this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger')
      }
    },
    readFile(files) {
      const res = {
        status: true,
        index: 0,
      }
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', 3000)
            this.resetInputFile()
            return
          }
          if (
            !Object.hasOwn(excellist[0], 'MA_THANG_DIEM_DANH_GIA_TONG_QUAN')
            || !Object.hasOwn(excellist[0], 'TEN_THANG_DIEM_DANH_GIA_TONG_QUAN')
            || !Object.hasOwn(excellist[0], 'TRANG_THAI_THANG_DIEM_DANH_GIA_TONG_QUAN')
            || !Object.hasOwn(excellist[0], 'TEN_MUC_THANG')
            || !Object.hasOwn(excellist[0], 'GIA_TRI_PHAN_TRAM_NHO_NHAT')
          ) {
            this.showToast('File chưa đúng định dạng, vui lòng tải file mẫu', 'XCircleIcon', 'danger', 3000)
            this.resetInputFile()
            return
          }
          this.dataExcel = []
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            // eslint-disable-next-line no-bitwise
            if (item.MA_THANG_DIEM_DANH_GIA_TONG_QUAN === '' || item.TEN_THANG_DIEM_DANH_GIA_TONG_QUAN === '' || item.TRANG_THAI_THANG_DIEM_DANH_GIA_TONG_QUAN === '' || item.TEN_MUC_THANG === '' || item.GIA_TRI_PHAN_TRAM_NHO_NHAT === '') {
              this.showToast(`Dữ liệu đầu vào không được trống các trường thông tin [MA_THANG_DIEM_DANH_GIA_TONG_QUAN, TEN_THANG_DIEM_DANH_GIA_TONG_QUAN, TRANG_THAI_THANG_DIEM_DANH_GIA_TONG_QUAN, TEN_MUC_THANG, GIA_TRI_PHAN_TRAM_NHO_NHAT], Kiểm tra thông tin dòng ${k + 1}`, 'XCircleIcon', 'danger', 3000)
              this.resetInputFile()
              return
            }
            const obj = {
              rowNum: k + 1,
              organizationId: getUser().orgId,
              code: item.MA_THANG_DIEM_DANH_GIA_TONG_QUAN,
              name: item.TEN_THANG_DIEM_DANH_GIA_TONG_QUAN,
              status: item.TRANG_THAI_THANG_DIEM_DANH_GIA_TONG_QUAN,
              levelName: item.TEN_MUC_THANG,
              minimumPercent: item.GIA_TRI_PHAN_TRAM_NHO_NHAT,
            }
            this.dataExcel.push(obj)
          }
          this.showTableImport = true
          // this.tableImport.data = this.dataExcel
          // this.tableImport.totalRows = this.dataExcel.length
        } catch (e) {
          alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
      return res
    },
    downloadFile() {
      const columns = [
        { index: 1, width: 300 },
        { index: 2, width: 300 },
        { index: 3, width: 300 },
        { index: 4, width: 300 },
        { index: 5, width: 300 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_THANG_DIEM_DANH_GIA_TONG_QUAN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'TEN_THANG_DIEM_DANH_GIA_TONG_QUAN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'TRANG_THAI_THANG_DIEM_DANH_GIA_TONG_QUAN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'TEN_MUC_THANG',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'GIA_TRI_PHAN_TRAM_NHO_NHAT',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      const headerRow1 = {
        index: 2,
        cells: [
          {
            index: 1,
            value: 'TDDGTQ1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Thang đo đánh giá tổng quan 1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'Suất xắc',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 90,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow2 = {
        index: 3,
        cells: [
          {
            index: 1,
            value: 'TDDGTQ1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Thang đo đánh giá tổng quan 1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'Khá',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 70,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow3 = {
        index: 4,
        cells: [
          {
            index: 1,
            value: 'TDDGTQ1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Thang đo đánh giá tổng quan 1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'Trung bình',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 50,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow4 = {
        index: 5,
        cells: [
          {
            index: 1,
            value: 'TDDGTQ2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Thang đo đánh giá tổng quan 2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 0,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'Rất tốt',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 95,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow5 = {
        index: 6,
        cells: [
          {
            index: 1,
            value: 'TDDGTQ2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Thang đo đánh giá tổng quan 2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 0,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'Tốt',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 75,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow6 = {
        index: 7,
        cells: [
          {
            index: 1,
            value: 'TDDGTQ2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Thang đo đánh giá tổng quan 2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 0,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'Trung bình',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 55,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow8 = {
        index:9,
        cells: [
          {
            index: 1,
            colSpan: 1,
          },
          {
            index: 2,
            colSpan: 1,
          },
          {
            index: 3,
            value: '1: Hoạt động, 0: Không hoạt động',
            colSpan: 1,
          },
          {
            index: 4,
            colSpan: 1,
          },
          {
            index: 5,
            colSpan: 1,
          },
        ],
      }

      rows.push(headerRow)
      rows.push(headerRow1)
      rows.push(headerRow2)
      rows.push(headerRow3)
      rows.push(headerRow4)
      rows.push(headerRow5)
      rows.push(headerRow6)
      rows.push(headerRow8)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('thangDiemDanhGiaTongQuan.xlsx')
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    exportExcel() {
      if (this.dataResourecesExport.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      let excelData = []
      let data = []
      this.dataResourecesExport.forEach((item) => {
        data.push({ 'code': item.code, 'name': item.name, 'status': this.getStatusName(item.status), 'levelName': item.levelName, 'minimumPercent': item.minimumPercent })
      })

      const header = {}
      this.columnsExport.forEach(column => {
        header[column.field] = column.label
      })

      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(data[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      data.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        excelData.push(elm)
      })

      const ws = XLSX.utils.json_to_sheet(excelData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'ThangDiemDanhGiaTongQuan')
      XLSX.writeFile(wb, 'DanhSachThangDiemDanhGiaTongQuan.xlsx')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
